import PropTypes from "prop-types";

function Application(props) {
  const { previewImage, url, name, description } = props;

  return (
    <div className="application">
      <h2 className="heading row">
        <a target="_blank" rel="noopener noreferrer" href={url}>
          {name}
        </a>
      </h2>
      <div className="content row">
        <div
          className="col-md"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        <div className="col-md">
          <a target="_blank" rel="noopener noreferrer" href={url}>
            <img src={require(`${previewImage}`)} alt={`${name} screenshot`} />
          </a>
        </div>
      </div>
    </div>
  );
}

Application.propTypes = {
  previewImage: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
};

export default Application;
