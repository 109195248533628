import "./FooterHeader.css";
import usgs_identifier from "./img/logo.png";

function Header() {
  return (
    <div className="header fixed-top">
      <div id="usgsId">
        <img className="logo" src={usgs_identifier} alt="USGS Logo" />
      </div>
    </div>
  );
}
export default Header;
