function Footer() {
  return (
    <div id="usgsfooter" className="fixed-bottom">
      <p id="usgsfooterbar">
        <a
          href="https://www.usgs.gov/privacy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          DOI Privacy Policy
        </a>
        <a
          href="https://www.usgs.gov/policies_notices.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal
        </a>
        ::
        <a
          href="https://www.usgs.gov/accessibility-and-us-geological-survey"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accessibility
        </a>
        <a
          href="https://www.usgs.gov/sitemap"
          target="_blank"
          rel="noopener noreferrer"
        >
          Site Map
        </a>
        <a
          href="https://answers.usgs.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact USGS
        </a>
        <a
          href="https://www.doi.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          U.S. Department of the Interior
        </a>
        <a
          href="https://www.doioig.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          DOI Inspector General
        </a>
        <a
          href="https://www.whitehouse.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          White House
        </a>
        <a
          href="https://www.whitehouse.gov/omb/management/egov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          E-gov
        </a>
        <a
          href="https://obamawhitehouse.archives.gov/open"
          target="_blank"
          rel="noopener noreferrer"
        >
          Open Government
        </a>
        <a
          href="https://www.doi.gov/pmb/eeo/reports-repository"
          target="_blank"
          rel="noopener noreferrer"
        >
          No Fear Act
        </a>
        <a
          href="https://www.usgs.gov/about/organization/science-support/foia"
          target="_blank"
          rel="noopener noreferrer"
        >
          FOIA
        </a>
      </p>
    </div>
  );
}
export default Footer;
