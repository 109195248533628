import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer";
import Header from "./Header";
import Application from "./Application";
const config = require("../src/config.json");

function App() {
  return (
    <div>
      <Header />
      <div className="scroller">
        {config.external.map((item, index) => (
          <Application
            key={index}
            name={item.name}
            description={item.description}
            url={item.url}
            previewImage={item.previewImage}
          ></Application>
        ))}
        <div className="application">
          <b>The following are for internal use only:</b>
        </div>
        {config.internal.map((item, index) => (
          <Application
            key={index}
            name={item.name}
            description={item.description}
            url={item.url}
            previewImage={item.previewImage}
          ></Application>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default App;
